// src/components/Users.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import '../CSS/AllUsers.css'; // Import the CSS file for styling
import { Context } from './Context';

const AllUsers = () => {
    const {getAllUsersUrl}=useContext(Context)
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${getAllUsersUrl}`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    
<div className="users-wrapper">
<h1 className="users-heading">Users</h1>
<input
  type="text"
  placeholder="Search by Email"
  value={search}
  onChange={(e) => setSearch(e.target.value)}
  className="users-search-input"
/>
<div className="users-list">
  {filteredUsers.map((user) => (
    <div key={user._id} className="user-card">
      <div className="user-detail">
        <span className="user-label">Full Name:</span>
        <span className="user-value">{user.fullName}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Email:</span>
        <span className="user-value">{user.email}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Phone Number:</span>
        <span className="user-value">{user.phoneNumber}</span>
      </div>
      <div className="user-detail">
        <span className="user-label">Referrer Code:</span>
        <span className="user-value">{user.referrerCode}</span>
      </div>
    </div>
  ))}
</div>
</div>
  );
};

export default AllUsers;
