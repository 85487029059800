import React from 'react';
import { FaLock, FaInfoCircle, FaUserShield, FaExchangeAlt, FaUserSecret, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../CSS/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicyContainerWrap'>
        <motion.div className="PrivacyPolicy-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }}>
      <h1 className="PrivacyPolicy-title">PRIVACY POLICY FOR ELEXDON DIGITAL ACADEMY</h1>
      <p><strong>Effective Date:</strong> 1st September 2024</p>

      <div className="PrivacyPolicy-section">
        <FaInfoCircle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Introduction</h2>
        <p>
          ELEXDON Digital Academy (referred to as “we”, “us”, or “our”) is committed to protecting the privacy and security of your personal information. This privacy policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, enroll in our courses, or use our services.
        </p>
      </div>

      <div className="PrivacyPolicy-section" style={{textAlign:"left"}} >
        <FaUserShield className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle" >Information Collection</h2>
        <p>We collect personal information in several ways, including when you:</p>
        <ul className="PrivacyPolicy-list">
          <li className="PrivacyPolicy-list-item">Register for an account or enroll in a course.</li>
          <li className="PrivacyPolicy-list-item">Participate in community forums or activities.</li>
          <li className="PrivacyPolicy-list-item">Contact us for support or inquiries.</li>
        </ul>
        <p>The types of personal information collected may include your name, email address, phone number, billing information, and any other information you choose to provide.</p>
      </div>

      <div className="PrivacyPolicy-section"style={{textAlign:"left"}} >
        <FaExchangeAlt className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Use of Information</h2>
        <p>Your personal information is used to:</p>
        <ul className="PrivacyPolicy-list">
          <li className="PrivacyPolicy-list-item">Provide, operate, and maintain our services.</li>
          <li className="PrivacyPolicy-list-item">Process transactions and send related information, including confirmations and invoices.</li>
          <li className="PrivacyPolicy-list-item">Send you technical notices, updates, security alerts, and support messages.</li>
          <li className="PrivacyPolicy-list-item">Respond to your comments, questions, and provide customer service.</li>
          <li className="PrivacyPolicy-list-item">Communicate with you about products, services, offers, promotions, and events.</li>
        </ul>
      </div>

      <div className="PrivacyPolicy-section" style={{textAlign:"left"}}>
        <FaUserSecret className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Sharing of Information</h2>
        <p>We do not sell your personal information. We may share information with third parties in the following situations:</p>
        <ul className="PrivacyPolicy-list">
          <li className="PrivacyPolicy-list-item">With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
          <li className="PrivacyPolicy-list-item">In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process.</li>
          <li className="PrivacyPolicy-list-item">With your consent or at your direction.</li>
        </ul>
      </div>

      <div className="PrivacyPolicy-section">
        <FaLock className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Data Security</h2>
        <p>
          We implement reasonable measures to help protect the security of your personal information and take steps to verify your identity before granting access to your information. However, no security system is impenetrable, and we cannot guarantee the security of our database, nor can we guarantee that the information you supply will not be intercepted while being transmitted to us over the Internet.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaUserShield className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Your Rights</h2>
        <p>
          You have certain rights regarding your personal information, including the right to access, correct, or delete the information we hold about you. You can exercise these rights at any time by contacting us.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaInfoCircle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Changes to this Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. The updated version will be indicated by an updated “Effective Date” and the updated version will be effective as soon as it is accessible.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaEnvelope className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Contact Us</h2>
        <p>
          If you have any questions or concerns about this privacy policy, please contact us at <a className="PrivacyPolicy-link" href="mailto:info@elexdontech.com">info@elexdontech.com</a>.
        </p>
      </div>
    </motion.div>
    </div>
  );
};

export default PrivacyPolicy;
