import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../CSS/AboutUs.css';
import AbImg1 from "../Images/mission.jpeg"
import AbImg2 from "../Images/vision.jpeg"
import AbImg3 from "../Images/Ab3.jpeg"
import AbImg4 from "../Images/Ab4.jpeg"


const aboutContent = [
    {
        title: "Our Mission",
        image: AbImg1,
        content: "Our mission is to empower individuals with the tech skills they need to succeed in today's digital world."
    },
    {
        title: "Our Vision",
        image: AbImg2,
        content: "Our vision is to be the leading online academy for tech education, helping learners across the globe achieve their career goals."
    },
    {
        title: "Our Values",
        image: AbImg3,
        content: "We value quality education, inclusivity, and lifelong learning. Our courses are designed to be accessible and comprehensive."
    },
    {
        title: "Why Choose Us?",
        image: AbImg4,
        content: "We offer flexible learning schedules, experienced instructors, and a supportive community. Our graduates are job-ready and highly skilled."
    }
];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const AboutUs = () => {
    return (
        <div className="about-us" id="about">
            <h2>ABOUT ELEXDON DIGITAL ACADEMY</h2>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                showDots={true}
                renderButtonGroupOutside={true}
            >
                {aboutContent.map((item, index) => (
                    <div key={index} className="about-item">
                        <img src={item.image} alt={item.title} className="about-image" />
                        <div className="about-content">
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default AboutUs;
