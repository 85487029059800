import React from 'react';
import '../CSS/BecomeInstructor.css';
import IntrsuctorImg from "../Images/instructorImg1.png"
import { useNavigate } from 'react-router-dom';

const BecomeInstructor = () => {
    const navigate=useNavigate()
    return (
        <div className="instructor-section">
            <div className="instructor-content">
                <h2 className="instructor-title">Become an Instructor</h2>
                <p className="instructor-description">
                    Join Elexdon Digital Academy and share your expertise with our global community. We are looking for passionate individuals who are skilled in their field and eager to teach. If you're ready to share your knowledge and inspire others, we want to hear from you!
                </p>
                <button className="instructor-button" onClick={() => navigate('/becomeinstructorpage')}>
                    Learn More
                </button>
            </div>
            <div className="instructor-image">
                <img src={IntrsuctorImg} alt="Become an Instructor" />
            </div>
        </div>
    );
};

export default BecomeInstructor;
