import React, { useState, useEffect } from 'react';
import '../CSS/BecomeInstructorPage.css';
import IntrsuctorImg from "../Images/instructorImg1.png"
import IntstructorImg2 from "../Images/instructorImg2.png"
import InstructorImg3 from "../Images/instructorImg3.png"
import { FaBackward } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const BecomeInstructorPage = () => {
    const navigate= useNavigate()
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);

    // Function to handle clicks outside the modal
    const handleOutsideClick = (e, setShowModal) => {
        if (e.target.classList.contains('modal-overlay')) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        // Add event listener for closing modals on escape key press
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setShowTerms(false);
                setShowPrivacy(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="become-instructor-page">
            <h1 className="page-title">Become an Instructor</h1>
            <div className="content-section">
                <img src={IntrsuctorImg} alt="Instructor Banner" className="banner-image" />
                <p>
                   
We’re on the lookout for experienced experts to join our team as instructors. If you have a deep understanding of a particular subject and a genuine passion for teaching, we want to hear from you. As an instructor, you’ll be responsible for developing and presenting dynamic lessons, monitoring student progress, and giving valuable feedback. To be considered, you should have substantial industry experience, relevant educational qualifications, and strong communication skills.

      </p>
                <p>
                Ready to make a difference? Click “Apply Here” below to get started.    
                </p>
                <br/>
                <p style={{textAlign:"center"}}>Click to read our<br/> <span className="link" onClick={() => setShowTerms(true)}>Instructor Terms and Conditions</span>  and  <span className="link" onClick={() => setShowPrivacy(true)}>Instructor Privacy Policy</span>.</p>
                <button className="apply-button" onClick={()=>navigate("/instructorregistrationform")}>Apply Here</button>
            </div>

            {/* Modal for Terms and Conditions */}
            {showTerms && (
                <div className="modal-overlay" onClick={(e) => handleOutsideClick(e, setShowTerms)}>
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowTerms(false)}>&times;</span>
                        <h2>Instructor Terms and Conditions</h2>
                        <p>
                            {/* Terms and conditions content goes here */}
                            <br/>
                            1. Agreement Overview

By becoming an instructor at Elexdon Digital Academy, you agree to the following terms and conditions. This agreement outlines the rights and responsibilities of both the instructor and the academy.
<br/><br/>
2. Eligibility

To become an instructor, you must:

Have significant expertise in your subject area.
Possess a relevant degree or certification.
Demonstrate strong communication and presentation skills.
Be legally authorized to work and receive payment in your country.
<br/><br/>
3. Course Content

Instructors are responsible for creating original and high-quality course content.
All content must comply with Elexdon Digital Academy's standards and guidelines, and should not infringe upon any third-party intellectual property rights.
The academy reserves the right to review, edit, or remove content that does not meet these standards or is deemed inappropriate.
<br/><br/>
4. Compensation

Instructors will receive compensation based on the agreed-upon terms for their courses, which may include revenue-sharing models or fixed fees.
Payments will be made in accordance with the payment schedule outlined in your instructor agreement.
<br/><br/>
5. Intellectual Property

Instructors retain ownership of their content but grant Elexdon Digital Academy a non-exclusive, worldwide license to use, distribute, and promote the content.
This license is transferable and sub-licensable to allow for marketing and distribution through various channels.
<br/><br/>
6. Confidentiality

Instructors must keep all confidential information, including student data and proprietary academy information, secure and confidential.
This obligation continues even after the termination of your relationship with Elexdon Digital Academy.
<br/><br/>
7. Termination

Either party may terminate this agreement with written notice. In the event of termination, the instructor must cease using all Elexdon Digital Academy platforms and return or destroy all confidential information.
<br/><br/>
8. Code of Conduct

Instructors must adhere to a professional code of conduct, treating all students and academy staff with respect and integrity.
Any form of discrimination, harassment, or unethical behavior will not be tolerated and may result in immediate termination.
<br/><br/>
9. Limitation of Liability

Elexdon Digital Academy is not liable for any indirect, incidental, or consequential damages arising from your role as an instructor.
The academy's total liability for any claims related to this agreement is limited to the amount paid to you under this agreement.
                        </p>
                    </div>
                </div>
            )}

            {/* Modal for Privacy Policy */}
            {showPrivacy && (
                <div className="modal-overlay" onClick={(e) => handleOutsideClick(e, setShowPrivacy)}>
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowPrivacy(false)}>&times;</span>
                        <h2>Instructor Privacy Policy</h2>
                        <p>
                            {/* Privacy policy content goes here */}
                            1. Introduction

Elexdon Digital Academy is committed to protecting the privacy of our instructors. This privacy policy outlines how we collect, use, and safeguard your personal information.

<br/><br/>
2. Information We Collect

Personal Information: Includes your name, email address, phone number, and payment details.
Professional Information: Includes your qualifications, experience, and the courses you offer.
Usage Data: Information about your interactions with our platform, such as login times and course materials accessed.
<br/><br/>
3. How We Use Your Information
<br/><br/>
Course Management: To create and manage your instructor profile and course offerings.<br/>
Payment Processing: To facilitate payments for your courses.<br/>
Communication: To contact you regarding course updates, feedback, and academy news.<br/>
Improvement of Services: To analyze usage data and improve our platform and services.
<br/><br/>
4. Data Sharing and Disclosure

With Third Parties: We may share your information with third-party service providers who assist with payment processing, marketing, and platform maintenance. These third parties are obligated to protect your information.
Legal Requirements: We may disclose your information if required by law or in response to legal processes.
<br/><br/>
5. Data Security

We implement a variety of security measures to protect your personal information. However, no system is completely secure, and we cannot guarantee the absolute security of your data.
<br/><br/>
6. Your Rights

Access and Correction: You have the right to access and correct your personal information.
Data Deletion: You can request the deletion of your personal information, subject to legal and contractual restrictions.
Opt-Out: You can opt-out of receiving marketing communications from us.
<br/><br/>
7. Changes to This Policy

We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website.
<br/><br/>
8. Contact Information

If you have any questions or concerns about this privacy policy, please contact us at info@elexdontech.com.
                        </p>
                    </div>
                </div>
            )}

            <div className="faq-section">
                <h2>Instructor FAQ</h2>
                <div className="faq-item">
                    <h3>What qualifications are needed to become an instructor?</h3>
                    <p>Relevant experience in your field, a degree or certification, and strong communication skills.</p>
                </div>
                <div className="faq-item">
                    <h3>What is the application process like?</h3>
                    <p>Submit your application through our website, including your resume and a brief description of your expertise.</p>
                </div>
                <div className="faq-item">
                    <h3>How are instructors compensated?</h3>
                    <p>Instructors are compensated based on the number of courses taught and student enrollment.</p>
                </div>
            </div>

            <div className="instructors-section">
                <h2>Meet Our Current Instructors</h2>
                <div className="instructors-list">
                    <div className="instructor-card">
                        <img src={IntstructorImg2} alt="Instructor 1" className="instructor-image" />
                        <h3>John Richard</h3>
                        <p>Software Engineer</p>
                    </div>
                    <div className="instructor-card">
                        <img src={InstructorImg3} alt="Instructor 2" className="instructor-image" />
                        <h3>Jane Smith</h3>
                        <p>Graphic Designer</p>
                    </div>
                    
                  
                </div>
            </div>
            <div className='CustomBackButtonWrap'>
                <button onClick={()=>window.history.back()}><FaBackward/> Back</button>
            </div>

            
        </div>
    );
};

export default BecomeInstructorPage;
