import React, { useContext, useState } from 'react';
import '../CSS/InstructorRegistrationForm.css';
import axios from 'axios';
import Swal from "sweetalert2"
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Context } from './Context';
import { FaBackward } from 'react-icons/fa6';

const InstructorRegistrationForm = () => {
    const {instructorRegistrationUrl}=useContext(Context)
    const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subjectExpertise: '',
    education: '',
    experience: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'email' ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${instructorRegistrationUrl}`, formData);
      console.log('Form submitted successfully:', response.data);
      Swal.fire({icon:"success",text:"Your application is submitted and we shall get back to you , Thanks"})

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subjectExpertise: '',
        education: '',
        experience: '',
      });

    } catch (error) {
      console.error('Error submitting form:', error);
      Swal.fire({icon:"error",text:error})
    } finally{
        setIsSubmitting(false)
    }
  };

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

  return (
    <div className="registration-form-container">
      <h1 className="form-title">Instructor Registration</h1>
      <form className="registration-form" onSubmit={handleSubmit}>
        <div className="form-groupInst">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-groupInst">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-groupInst">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-groupInst">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-groupInst">
          <label htmlFor="subjectExpertise">Subject Expertise</label>
          <input
            type="text"
            id="subjectExpertise"
            name="subjectExpertise"
            value={formData.subjectExpertise}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-groupInst">
          <label htmlFor="education">Education and Qualifications</label>
          <textarea
            id="education"
            name="education"
            value={formData.education}
            onChange={handleChange}
            rows="4"
            required
          />
        </div>
        <div className="form-groupInst">
          <label htmlFor="experience">Relevant Experience</label>
          <textarea
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            rows="4"
            required
          />
        </div>
        <button type="submit" disabled={isSubmitting} className='submit-button'>
            {isSubmitting ? (
              <ClipLoader color={"#ffffff"} loading={isSubmitting} css={override} size={15} />
            ) : (
              "Submit Application"
            )}
          </button>
      </form>
      <div className='CustomBackButtonWrap' >
                <button onClick={()=>window.history.back()}><FaBackward/> Back</button>
            </div>
    </div>
  );
};

export default InstructorRegistrationForm;
