import React from 'react';
import '../CSS/Categories.css';
import Il1 from "../Images/il1.jpeg"
import Il2 from "../Images/il2.jpeg"
import Il3 from "../Images/il3.jpeg"
import Il4 from "../Images/il4.jpeg"

const categories = [
    {
        name: "Web Development",
        image: Il1,
        description: "Learn how to build websites and web applications."
    },
    {
        name: "UI/UX Design",
        image: Il2,
        description: "Design user interfaces and user experiences."
    },
    {
        name: "Digital Marketing",
        image: Il3,
        description: "Master the art of online marketing and SEO."
    },
    {
        name: "Product Design",
        image: Il4,
        description: "Learn to design products that users love."
    }
];

const Categories = () => {
    return (
        <div className="categories">
            <h2>OUR COURSE CATEGORIES</h2>
            <div className="categories-container">
                {categories.map((category, index) => (
                    <div key={index} className="category-card">
                        <img src={category.image} alt={category.name} />
                        <h3>{category.name}</h3>
                        <p>{category.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
