

import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../Features/Slice";
import "../CSS/OrderSummaryPage.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from "./Context";
import { FaBitcoin } from "react-icons/fa";
import PaystackPop from "@paystack/inline-js";
import Swal from "sweetalert2";
import axios from "axios";

const OrderSummaryPage = () => {
  const location = useLocation()
  const { activePage, setActivePage, orderSendEmailUrl, orderListPostUrl, confirmPaymentUrl } = useContext(Context);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deliveryDetails = useSelector((state) => state.deliveryDetails);
  const cart = useSelector((state) => state.cart);
  const userInfo = useSelector((state) => state.userInfo);

  // console.log(userInfo)

  const calculateTotal = () => {
    return cart.reduce((total, item) => {
      // Ensure item.price is a valid number, default to 0 if not
      const price = parseFloat(item.price) || 0;
      return total + price;
    }, 0);
  };
  

 
  

  const postOrderFunction = async (reference) => {
    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: reference,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode:userInfo.referrerCode,
      cartItems: cart.map(item => ({ id: item.id, title: `${item.title} by ${item.author} - ${item.id}`, price: item.price })), // Convert cart items to array of objects
      total: calculateTotal(),
      currency:"NGN"
    };

    try {
      const response = await axios.post(orderListPostUrl, orderSummary);
      console.log('Order saved:', response.data);
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: response.data.message,
        showConfirmButton: false,
        timer: 2000
      });

      dispatch(clearCart());
      navigate("/userdashboard");
      setActivePage('courses');
    } catch (error) {
      console.error('Error saving order:', error);
      Swal.fire({
        icon: "error",
        title: "Order Failed",
        text: error.response?.data?.error || 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 2000
      });
    }
  };

  const sendOrderEmailFunction = async (reference) => {
    console.log("hey2")
    const loadingAlert = Swal.fire({
      title: "Loading",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false
    });

    Swal.showLoading();

    const getCurrentDateTime = () => {
      const now = new Date();
      const offset = now.getTimezoneOffset() * 60000;
      const localTime = new Date(now.getTime() - offset);
      return localTime.toISOString().slice(0, 19).replace("T", " ");
    };

    const orderSummary = {
      userId: userInfo.id,
      date: getCurrentDateTime(),
      transactionRef: reference,
      name: deliveryDetails.name,
      phone: deliveryDetails.phoneNumber,
      email: deliveryDetails.email,
      referrerCode:userInfo.referrerCode,
      cartItems: cart.map(item => `${item.title} by ${item.author} ${item.id} - ₦${new Intl.NumberFormat().format(item.price)}`),
      total: `₦${new Intl.NumberFormat().format(calculateTotal())}`
    };

    try {
      const response = await axios.post(orderSendEmailUrl, {
        buyerEmail: deliveryDetails.email,
        sellerEmail: 'info@elexdontech.com', //not doing anything here 
        orderSummary: JSON.stringify(orderSummary, null, 2)
      });

      if (response.status === 200) {
        Swal.fire({ icon: "success", text: "Order successful. Please check your email inbox or spam for details." });
      } else {
        Swal.fire({ icon: "error", text: "Failed to send order summary." });
      }
    } catch (error) {
      console.error('Error sending order email:', error);
      Swal.fire({ icon: "error", text: "An error occurred while sending the order summary." });
    } finally {
      loadingAlert.close();
    }
  };
 



  const payWithPaystack = () => {
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: "pk_live_3626fe7772aaca28a10724ebb1f9727dfcc5d6cb",
      // key: "pk_test_60e1f53bba7c80b60029bf611a26a66a9a22d4e4",
      amount: calculateTotal() * 100, // amount in kobo
      email: deliveryDetails.email,
      firstname: deliveryDetails.name,
      onSuccess: (transaction) => {
      //  alert(`${transaction.reference} success`)
      //  verifyTransaction(transaction.reference)
      postOrderFunction(transaction.reference);
      sendOrderEmailFunction(transaction.reference); 
      // console.log(`${transaction.reference} hey 1`)
      },
      onCancel: () => {
        Swal.fire({ icon: "error", text: "Payment cancelled.", showConfirmButton: true });
      },
      onError: (error) => {
        Swal.fire({ icon: "error", text: `Payment failed: ${error.message}`, showConfirmButton: true });
      }
    });
  }; 

//erify payemnet
const verifyTransaction = async (reference) => {
  const loadingAlert = Swal.fire({
    title: "Confirming payment",
    text: "Please wait...",
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false
  });

  Swal.showLoading();
  console.log(reference)
  try {
    const response = await axios.post(`${confirmPaymentUrl}`, { reference });

    if (response.data.status === 'success') {
      // Proceed with after-payment logic
      Swal.fire({
        icon: "success",
        text: "Payment successful!",
        showConfirmButton: true,
        timer: 2000
      });

      // Assuming transaction.reference is what you intend to use
      postOrderFunction(reference);
      sendOrderEmailFunction(reference);
      
      console.log('Payment verified successfully');
    } else {
      // Handle verification failure
      Swal.fire({
        icon: "error",
        text: `${response.data.message}`,
        showConfirmButton: true
      });
      console.error(response.data);
      console.log(reference)
    }
  } catch (error) {
    // Handle error during the request
    let errorMessage = 'Error verifying payment!';
    if (error.response) {
      if (error.response.data && error.response.data.message) {
        errorMessage = `Error verifying payment: ${error.response.data.message}`;
      } else {
        errorMessage = 'Unexpected error from server.';
      }
    } else if (error.request) {
      errorMessage = 'No response from server. Please check your internet connection.';
    } else {
      errorMessage = error.message;
    }

    Swal.fire({
      icon: "error",
      text: errorMessage,
      showConfirmButton: true
    });
    console.error('Error verifying payment:', error);
  } finally {
    loadingAlert.close();
  }
};




  // const handleCryptoPayment = () => {
  //   const totalAmount = calculateTotal();
  //   const bitcoinAddress = "Your_Bitcoin_Address_Here"; // Replace with your actual Bitcoin address from Binance

  //   Swal.fire({
  //     title: 'Bitcoin Payment',
  //     html: `
  //       <p>Please send exactly <strong>${totalAmount} USD worth of Bitcoin</strong> to the following address:</p>
  //       <p><strong>${bitcoinAddress}</strong></p>
  //       <p>Once the payment is made, enter the transaction ID below and click confirm.</p>
  //     `,
  //     input: 'text',
  //     inputPlaceholder: 'Enter your Bitcoin transaction ID',
  //     showCancelButton: true,
  //     confirmButtonText: 'Confirm Payment',
  //     preConfirm: (transactionId) => {
  //       if (!transactionId) {
  //         Swal.showValidationMessage('Please enter your Bitcoin transaction ID.');
  //       }
  //       return transactionId;
  //     }
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const transactionId = result.value;
  //       postOrderFunction(transactionId);
  //       sendOrderEmailFunction(transactionId);
  //       Swal.fire({ icon: "success", text: "Payment confirmed!", showConfirmButton: true, timer: 2000 });
  //     }
  //   });
  // };

  // const handleDollarPayment = () => {
  //   alert("Dollar payment");
  // };

  // const [selectedCurrency, setSelectedCurrency] = useState("");

  // const handlePayNow = () => {
  //   if (selectedCurrency === "naira") {
  //     payWithPaystack();
  //   } else if (selectedCurrency === "bitcoin") {
  //     handleCryptoPayment();
  //   } else if (selectedCurrency === "dollar") {
  //     handleDollarPayment();
  //   }
  // };

  return (
    <div className="order-summary-page" key={location.pathname}>
      <h2>ORDER SUMMARY</h2>
      <div className="delivery-details">
        <p><span style={{ color: "blue" }}>Name:</span> {deliveryDetails.name}</p>
        <p><span style={{ color: "blue" }}>Email:</span> {deliveryDetails.email}</p>
        <p><span style={{ color: "blue" }}>Phone:</span> {deliveryDetails.phoneNumber}</p>
        <p><span style={{ color: "blue" }}>Referrer Code:</span> {userInfo?.referrerCode}</p>
      </div>
      <div className="cart-details">
        <ul>
          {cart.map((item) => (
            <li key={item.id}>
              <div className="cart-item">
                <img src={item.imgSrc} alt="cartItemImg" />
                <div>
                <p className="item-name">{item.title}</p>
                <p style={{fontSize:"small",color:"gray"}}>{item.id}</p>
                <p style={{fontSize:"small",color:"gray"}}>{item.author}</p>
                </div>
                {/* <p className="item-price">Price: ₦ {item.price.toFixed(2)}</p> */}
                <p className="item-price">Price: ₦ {Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>

                {/* <p className="item-total">Total: ₦ {(item.price).toFixed(2)}</p> */}
                {/* <p className="item-total">Total: ₦ {Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p> */}

              </div>
            </li>
          ))}
        </ul>
        {/* <h3 className="cart-total">Cart Total: ₦ {calculateTotal().toFixed(2)}</h3> */}
        <h3 className="cart-total">Cart Total: ₦ {Number(calculateTotal()).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>

      </div>
      {/* <select className="PaymentCurrencySelect" onChange={(e) => setSelectedCurrency(e.target.value)}>
        <option value="">-- SELECT PAYMENT CURRENCY --</option>
        <option value="naira">₦ Naira (NGN)</option>
        <option value="dollar">$ Dollar (USD)</option>
        <option value="bitcoin"><FaBitcoin /> Bitcoin (BTC)</option>
      </select>
      {selectedCurrency.length > 0 && <button className="pay-now-button" onClick={handlePayNow}>Pay Now</button>} */}

      <button className="pay-now-button" onClick={payWithPaystack}>Pay Now</button>
    </div>
  );
};

export default OrderSummaryPage;
