import React from 'react';
import { FaInfoCircle, FaMoneyBillWave, FaExclamationCircle, FaTools, FaCommentDots, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../CSS/PrivacyPolicy.css';

const RefundPolicy = () => {
  return (
    <div className='PrivacyPolicyContainerWrap'>
        <motion.div className="PrivacyPolicy-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }}>
      <h1 className="PrivacyPolicy-title">ELEXDON DIGITAL ACADEMY REFUND POLICY</h1>
      <p><strong>Effective Date:</strong> 1st September 2024</p>

      <div className="PrivacyPolicy-section">
        <FaInfoCircle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Introduction</h2>
        <p>
          Welcome to ELEXDON Digital Academy. We are dedicated to providing our students with high-quality educational content and resources. Due to the nature of our digital products and services, we have established the following Refund Policy.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaMoneyBillWave className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Digital Product Nature</h2>
        <p>
          Our courses, including all related materials and resources, are provided as digital products. Unlike physical goods, digital products cannot be returned. Once a course is accessed by a student, it is considered “used” even if no downloads were made. This inherently limits our ability to offer refunds after access has been granted.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaExclamationCircle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">No Refund After Access Have Been Granted</h2>
        <p>
          Therefore, we do not offer refunds once a student has gained access to a course. Access is defined as logging into the course platform and viewing any of the course content. This policy is in place because once access is granted, the student benefits from the knowledge, course materials, and resources available, which cannot be returned.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaMoneyBillWave className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Prior to Access Refund Requests</h2>
        <p>
          If you have purchased access to a course but have not yet accessed any course materials, you may request a refund within 24 hours of purchase. Please contact our support team via email at <a className="PrivacyPolicy-link" href="mailto:support@elexdondigitalacademy.com">support@elexdondigitalacademy.com</a> or <a className="PrivacyPolicy-link" href="mailto:info@elexdontech.com">info@elexdontech.com</a> with your purchase details to initiate a refund request under these specific conditions.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaTools className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Exceptional Circumstances</h2>
        <p>
          We understand that exceptional circumstances may arise. If you believe your situation warrants an exception to our no-refund policy after accessing course materials, please submit a detailed explanation to our support team. We review such requests on a case-by-case basis, considering factors such as technical issues or extenuating personal circumstances.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaTools className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Technical Issues</h2>
        <p>
          If you experience technical difficulties accessing or navigating a course for which you have registered, please contact our support team immediately. We will work with you to resolve the issue, and if we are unable to provide access to the course as advertised, a refund may be offered.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaCommentDots className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Feedback and Complaints</h2>
        <p>
          Your satisfaction is important to us. If you have feedback or a complaint about a course or service, please reach out to our support team. We are committed to continuous improvement and value your insights.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaEnvelope className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Contact Us</h2>
        <p>
          For any questions regarding this Refund Policy or to submit a refund request under the conditions outlined above, please contact our support team at <a className="PrivacyPolicy-link" href="mailto:support@elexdondigitalacademy.com">support@elexdondigitalacademy.com</a>.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaInfoCircle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Conclusion</h2>
        <p>
          Thank you for choosing ELEXDON Digital Academy. We are committed to providing you with quality educational experiences and appreciate your understanding of our policies.
        </p>
      </div>
    </motion.div>
    </div>
  );
};

export default RefundPolicy;
