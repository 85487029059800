
import React, { useContext, useState } from 'react';
import { FaPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import "../CSS/LatestCourses.css";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Context } from './Context';
import axios from 'axios';
import "../CSS/AdminViewAllCourses.css"
import EditCourse from './EditCourse';

const AdminViewAllCourses = () => {
  const { courses, setCourses } = useContext(Context);
  const navigate = useNavigate();
  const {selectedCourse, setSelectedCourse} = useContext(Context);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (courseId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This course will be permanently deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
        const loadingAlert= Swal.fire({title:"Deleting..."})
        Swal.showLoading();
      try {
        const response = await axios.delete(`https://elexdondigitalacademy.com/api/delete_course.php?id=${courseId}`);
        
        // Debugging: Check the response data
        // console.log('Delete response:', response.data);
        
        if (response.data.status === 'success') {
          setCourses(courses.filter(course => course.id !== courseId));
          Swal.fire('Deleted!', 'The course has been deleted.', 'success');
        } else {
          Swal.fire('Error!', response.data.message || 'Failed to delete the course.', 'error');
        }
      } catch (error) {
        Swal.fire('Error!', 'Failed to delete the course.', 'error');
      } finally {
        // setLoading(false);
        loadingAlert.close()
      }
    }
  };

  const handleEdit = (id) => {
    setSelectedCourse(id);

  };

  return (
    <div className='LatestCourses' style={{ padding: "100px 0px", fontSize: "0.9rem"}}>
      {selectedCourse?<EditCourse/>:<div className='LatestCoursesBody' style={{ gap: "0px", justifyContent: "center", alignItems: "center" }}>
        {courses.map(course => (
          <div className='Card' key={course.id} style={{ display: "flex", width: "220px", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "0px", padding: "5px", margin: "5px" }}>
            <div className='CardUp'>
              <img src={course.imgSrc} alt={course.title} style={{ width: "100px", height: "100px", borderRadius: "10px" }} />
            </div>
            <div className='CardDown' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", padding: "0px", gap: "0px" }}>
              <div className='CardDownAuthor' style={{ marginBottom: "0px" }}>
                <span className='CardDownAuthorRight'>{course.author}</span>
              </div>
              <h4 style={{ marginBottom: "0px" }}>{course.title.slice(0, 40)}{course.title.length > 40 ? '...' : ""}</h4>
              <span style={{ fontSize: "12px", color: "gray" }}>{course.id}</span>
              <span>₦ {Number(course.price).toLocaleString()}</span>
              <span onClick={() => navigate(`/course/${course.id}`)} style={{ marginBottom: "5px", fontWeight: "400", fontSize: "0.8rem", cursor: "pointer", textDecoration: "underline", color: "blue" }}>View More</span>
              <div className='CardButtonAndShareWrap' style={{ gap: "10px" }}>
                <button style={{ padding: "5px" }} onClick={() => handleEdit(course.id)}><FaPenToSquare /> Edit</button>
                <button style={{ padding: "5px" }} onClick={() => handleDelete(course.id)} disabled={loading}>{loading ? 'Deleting...' : <><FaRegTrashCan /> Delete</>}</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      
    
          
  
      }
    </div>
  );
};

export default AdminViewAllCourses;

