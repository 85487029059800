import React from 'react'
import '../CSS/Footer.css'
import { Fa500Px, FaBitcoin, FaCcMastercard, FaCcVisa, FaElementor, FaFacebook, FaHackerNewsSquare, FaInstagram, FaTwitter, FaWeebly, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Logo from "../Images/ELogo.png"

const Footer = () => {
  const navigate = useNavigate()

  const handleNavigate = ()=>{
    navigate("/")
    scrollToAbout()
 }
 
 const scrollToAbout = ()=>{
    
       const int = setTimeout(()=>{
          const element = document.getElementById('about')
    if(element)
    element.scrollIntoView({behavior:'smooth'})
       },500)
 
       return ()=>clearInterval(int)
    
 }

  return (
    <div className='Footer'>
      <div className='FooterLogo'>
      <img src={Logo} alt="Logo"/>
      </div>
        {/* <div className='FooterLogo'><FaElementor/></div> */}
        <div className='FooterUp'><h2>ELEXDON DIGITAL ACADEMY</h2>
        <p>
          <FaWhatsapp style={{cursor:"pointer"}} onClick={()=>window.open("https://wa.me/+2348185609702","_blank")}/>
            <FaFacebook style={{cursor:"pointer"}} onClick={()=>window.open("https://www.facebook.com/profile.php?id=100032359376789&mibextid=LQQJ4d","_blank")}/>
              <FaInstagram style={{cursor:"pointer"}}/>
              <FaTwitter style={{cursor:"pointer"}}/>
              <FaYoutube style={{cursor:"pointer"}} onClick={()=>window.open("https://www.youtube.com/channel/UCrBjwZIqj6rvlyYyY4oyzdA","_blank")}/></p></div>
        <div className='FooterLine'></div>
        <div className='FooterDown'>
        <div className='Contact'>
            <h4>Contact</h4>
            {/* <p>Address<br/>Email<br/>Phone</p> */}<br/>
            <p>The old Vicarage building<br/>
Trinity Road Old Market Avon<br/>
Bristol– United Kingdom.</p><br/>
<p><strong>Email:</strong> info@elexdontech.com</p>
<p><strong>Phone:</strong> +44(0)7479305003</p>
<p><strong>Phone:</strong> +234 818 560 9702</p>

        </div>
        <div className='QuickLinks'>
            <h4 style={{textDecoration:"underline"}}>Quick Links</h4>
              <p onClick={()=>window.open("https://elexdontech.com/","_blank")}>Elexdon Digitech</p>
              <p onClick={()=>window.open("https://elexdonhost.com.ng/","_blank")}>Elexdon Host</p>
              <p onClick={()=>window.open("https://www.mikeconnect.com/","_blank")}>Mikeconnect</p>
        </div>


        <div className='QuickLinks'>
            <h4 style={{textDecoration:"underline"}}>Academy Links</h4>
            <p onClick={()=>{navigate("/");window.scroll(0,0)}}>Home</p>
            <p onClick={handleNavigate}>About Us</p>
            <p onClick={()=>{navigate("/allcourses");window.scroll(0,0)}}>Courses</p>
            <p onClick={()=>navigate("/termsofservice")}>Terms and Conditions</p>
            <p onClick={()=>navigate("/privacypolicy")}>Privacy Policy</p>
            <p onClick={()=>navigate("/refundpolicy")}>Refund Policy</p>
            <p onClick={()=>{navigate("/contactus");window.scroll(0,0)}}>Contact Us</p>
        </div>
        <div className='PaymentMethods'>
            <h4>Payment Methods</h4>
            <div className='PaymentMethodIconsWrap'>
            <p><FaCcMastercard/></p><p><FaCcVisa/></p><p><FaBitcoin/></p>
            </div>
        </div>
        </div>
        <div className='CopyRight'><p>© Copyright {new Date().getFullYear()} Elexdon Digital Technologies Limited. All Rights Reserved</p>
        <p style={{fontSize:"0.7rem",color:"rgba(255,255,255,0.6)"}}><span style={{color:"rgba(0,255,0,0.7)"}}>POWERED BY</span> ELEXDON DIGITAL TECHNOLOGIES LIMITED</p>
      </div>
    </div>
  )
}

export default Footer
