import React from 'react';
import { FaInfoCircle, FaUserCheck, FaBookOpen, FaUserAltSlash, FaDollarSign, FaExclamationTriangle, FaBalanceScale, FaEdit, FaGavel, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../CSS/PrivacyPolicy.css';

const TermsOfService = () => {
  return (
    <div className='PrivacyPolicyContainerWrap'>
        <motion.div className="PrivacyPolicy-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 3 }}>
      <h1 className="PrivacyPolicy-title">ELEXDON DIGITAL ACADEMY TERMS AND CONDITIONS</h1>
      <p><strong>Effective Date:</strong> 1st September 2024</p>

      <div className="PrivacyPolicy-section">
        <FaInfoCircle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">1. Introduction</h2>
        <p>
          Welcome to ELEXDON Digital Academy. These Terms and Conditions (“Terms”) govern your access to and use of our website, courses, and services (“Services”). By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy.
        </p>
      </div>

      <div className="PrivacyPolicy-section" >
        <FaUserCheck className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">2. Use of Services</h2>
        <p style={{textAlign:"left"}}>
          a. <strong>ELIGIBILITY:</strong> You must be at least 18 years old to use our Services or have consent from a legal guardian.
        </p>
        <p style={{textAlign:"left"}}>
          b. <strong>ACCOUNT REGISTRATION:</strong> To access certain features, you may be required to create an account. You agree to provide accurate information and keep your account details secure.
        </p>
        <p style={{textAlign:"left"}}>
          c. <strong>COURSE ENROLLMENT:</strong> Enrollment in our courses is subject to acceptance by ELEXDON Digital Academy and may require payment of fees.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaBookOpen className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">3. Intellectual Property Rights</h2>
        <p>
          All content provided through our Services, including text, graphics, logos, and course materials, is the property of ELEXDON Digital Academy or its partners and licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the Services for personal, non-commercial purposes.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaUserAltSlash className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">4. User Conduct</h2>
        <p>
          You agree not to misuse our Services. This includes not engaging in illegal activities, infringing on the rights of others, or disrupting the Services in any way.
        </p>
      </div>

      <div className="PrivacyPolicy-section" >
        <FaDollarSign className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">5. Payments and Refunds</h2>
        <p style={{textAlign:"left"}}>
          a. <strong>Fees:</strong> Fees for our courses and services are detailed on our website. We reserve the right to change our prices at any time.
        </p>
        <p style={{textAlign:"left"}}>
          b. <strong>Refunds:</strong> Refunds are available under certain conditions, as detailed in our Refund Policy.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaExclamationTriangle className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">6. Disclaimer of Warranties</h2>
        <p>
          Our Services are provided “as is” without any warranties of any kind, either express or implied. ELEXDON Digital Academy does not guarantee the accuracy, reliability, or completeness of the content provided.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaBalanceScale className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">7. Limitation of Liability</h2>
        <p>
          ELEXDON Digital Academy shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your access to or use of, or inability to access or use, the Services.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaEdit className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">8. Changes to Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. Your continued use of the Services after any changes signifies your acceptance of the new Terms.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaGavel className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of Nigeria without regard to its conflict of law provisions.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaEnvelope className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">10. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at <a className="PrivacyPolicy-link" href="mailto:info@elexdontech.com">info@elexdontech.com</a>.
        </p>
      </div>

      <div className="PrivacyPolicy-section">
        <FaUserCheck className="PrivacyPolicy-icon" />
        <h2 className="PrivacyPolicy-subtitle">Acceptance of Terms</h2>
        <p>
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
        </p>
      </div>
    </motion.div>
    </div>
  );
};

export default TermsOfService;
