
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../CSS/AdminPostCourse.css';
import Swal from 'sweetalert2';
import { Context } from './Context';

const AdminPostCourse = () => {
  const {getAllCourses}=useContext(Context)
  const generateCourseId = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
    return `CRS${year}${month}${day}${randomNum}`;
  };

  const [courseData, setCourseData] = useState({
    id: generateCourseId(),
    title: '',
    author: '',
    price: '',
    imgSrc: '',
    type: 'Video Course',
    description: '',
    lessons: [{ title: '', videoUrl: '' }],
  });

  const [authorType, setAuthorType] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name==="title"){
      setCourseData({
        ...courseData,
        [name]: value.toUpperCase(),
      });
    }else{
      setCourseData({
        ...courseData,
        [name]: value,
      });
    }
  };

  const handleAuthorChange = (e) => {
    const selectedAuthor = e.target.value;
    setAuthorType(selectedAuthor);

    if (selectedAuthor === 'Elexdon Digital Academy') {
      setCourseData({
        ...courseData,
        author: 'Elexdon Digital Academy',
      });
    } else {
      setCourseData({
        ...courseData,
        author: '',
      });
    }
  };

  const handleLessonChange = (index, e) => {
    const { name, value } = e.target;
    const updatedLessons = [...courseData.lessons];
    updatedLessons[index][name] = value;
    setCourseData({
      ...courseData,
      lessons: updatedLessons,
    });
  };

  const addLesson = () => {
    setCourseData({
      ...courseData,
      lessons: [...courseData.lessons, { title: '', videoUrl: '' }],
    });
  };

  const removeLesson = (index) => {
    const updatedLessons = courseData.lessons.filter((_, lIndex) => lIndex !== index);
    setCourseData({
      ...courseData,
      lessons: updatedLessons,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingAlert = Swal.fire({ title: "Posting..." });
    Swal.showLoading();
    try {
      await axios.post("https://elexdondigitalacademy.com/api/post_course.php", courseData);
      Swal.fire({
        icon: 'success',
        title: 'Course Posted',
        text: 'The course has been posted successfully!',
      });
      getAllCourses();
      setCourseData({
        id: generateCourseId(),
        title: '',
        author: '',
        price: '',
        imgSrc: '',
        type: 'Video Course',
        description: '',
        lessons: [{ title: '', videoUrl: '' }],
      });
      setAuthorType('');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error posting the course.',
      });
    } finally {
      loadingAlert.close();
    }
  };


  useEffect(()=>{
    Swal.fire({title:"Hi Admin",text:"Ensure that you post the Lessons sequentially for better user experience"})
  },[])

  return (
    <div className="admin-post-course-container">
      <h1 className="admin-post-course-title">Post A Course</h1>
      <form onSubmit={handleSubmit} className="admin-post-course-form">
        <div className="admin-post-course-form-group">
          <label htmlFor="id" className="admin-post-course-label">Course ID</label>
          <input
            type="text"
            id="id"
            name="id"
            value={courseData.id}
            required
            disabled
            className="admin-post-course-input"
          />
        </div>
        <div className="admin-post-course-form-group">
          <label htmlFor="title" className="admin-post-course-label">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={courseData.title}
            onChange={handleChange}
            required
            className="admin-post-course-input"
            placeholder='Enter Course title'
          />
        </div>
        <div className="admin-post-course-form-group">
          <label htmlFor="author" className="admin-post-course-label">Author</label>
          <select
            className="admin-post-course-input"
            required
            style={{ outline: "none" }}
            onChange={handleAuthorChange}
            value={authorType}
          >
            <option value="" disabled>Select actual course Author</option>
            <option value="Elexdon Digital Academy">Elexdon Digital Academy</option>
            <option value="other">Other</option>
          </select>
        </div>
        {authorType === 'other' && (
          <div className="admin-post-course-form-group">
            <label htmlFor="customAuthor" className="admin-post-course-label">Specify Author</label>
            <input
              type="text"
              id="customAuthor"
              name="author"
              value={courseData.author}
              onChange={handleChange}
              required
              className="admin-post-course-input"
              placeholder='Enter Author Name'
            />
          </div>
        )}
        <div className="admin-post-course-form-group">
          <label htmlFor="price" className="admin-post-course-label">Price (NGN)</label>
          <input
            type="number"
            id="price"
            name="price"
            value={courseData.price}
            onChange={handleChange}
            required
            className="admin-post-course-input"
          />
        </div>
        <div className="admin-post-course-form-group">
          <label htmlFor="imgSrc" className="admin-post-course-label">Cover image Source</label>
          <input
            type="text"
            id="imgSrc"
            name="imgSrc"
            value={courseData.imgSrc}
            onChange={handleChange}
            className="admin-post-course-input"
            placeholder='Enter cover Image Url'
          />
        </div>
        <div className="admin-post-course-form-group">
          <label htmlFor="description" className="admin-post-course-label">Description</label>
          <textarea
            id="description"
            name="description"
            value={courseData.description}
            onChange={handleChange}
            required
            className="admin-post-course-textarea"
            placeholder='Enter course description'
          />
        </div>
        <div className="admin-post-course-form-group">
          <label className="admin-post-course-label">Lessons</label>
          {courseData.lessons.map((lesson, lessonIndex) => (
            <div>
              <p>Lesson {lessonIndex+1}</p>
              <div key={lessonIndex} className="admin-post-course-lesson-item">
              
              <input
                type="text"
                name="title"
                value={lesson.title}
                onChange={(e) => handleLessonChange(lessonIndex, e)}
                placeholder="Lesson Title"
                className="admin-post-course-input"
              />
              <input
                type="text"
                name="videoUrl"
                value={lesson.videoUrl}
                onChange={(e) => handleLessonChange(lessonIndex, e)}
                placeholder="Video URL"
                className="admin-post-course-input"
              />
              <button
                type="button"
                onClick={() => removeLesson(lessonIndex)}
                className="admin-post-course-remove-lesson"
              >
                Remove Lesson
              </button>
            </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addLesson}
            className="admin-post-course-add-lesson"
          >
            Add Lesson
          </button>
        </div>
        <button type="submit" className="admin-post-course-submit-button">Post Course</button>
      </form>
    </div>
  );
};

export default AdminPostCourse;

