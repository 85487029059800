

// import React, { useState, useEffect, useContext } from 'react';
// import Swal from 'sweetalert2';
// import axios from 'axios';
// import { Context } from './Context';

// const EditCourse = () => {
//   const { selectedCourse, setSelectedCourse } = useContext(Context);
//   const [course, setCourse] = useState({
//     title: '',
//     author: '',
//     price: '',
//     imgSrc: '',
//     description: '',
//     lessons: [],
//   });

//   // Fetch course data when the selectedCourse changes
//   useEffect(() => {
//     if (selectedCourse) {
//         const loadingAlert = Swal.fire({title:"Fetching Course Details"})
//         Swal.showLoading();
//       axios.get(`https://elexdondigitalacademy.com/api/get_course.php?id=${selectedCourse}`)
//         .then(response => {
//           setCourse(response.data.course);
//           loadingAlert.close();
//         })
//         .catch(error => {
//           console.error('Error fetching course:', error);
//           loadingAlert.close();
//         });
//     }
//   }, [selectedCourse]);

//   // Handle input change for course-level fields
//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setCourse({
//       ...course,
//       [name]: value,
//     });
//   };

//   // Handle input change for lesson-level fields
//   const handleLessonChange = (index, event) => {
//     const { name, value } = event.target;
//     const updatedLessons = course.lessons.map((lesson, lessonIndex) =>
//       lessonIndex === index ? { ...lesson, [name]: value } : lesson
//     );
//     setCourse({
//       ...course,
//       lessons: updatedLessons,
//     });
//   };

//   // Function to handle saving changes
//   const handleSaveChanges = () => {
//     const loadingAlert = Swal.fire({title:"Updating..."})
//     Swal.showLoading();
//     axios.post('https://elexdondigitalacademy.com/api/update_course.php', course)
//       .then(response => {
//         Swal.fire({
//           icon: 'success',
//           title: 'Course updated successfully!',
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         setSelectedCourse(null); // Close the editor after saving
//         window.scroll(0,0)
//       })
//       .catch(error => {
//         console.error('Error updating course:', error);
//         Swal.fire({
//           icon: 'error',
//           title: 'Error updating course!',
//           text: 'Please try again.',
//         });
//       });
//   };

//   return (
//     <div className='ECourseDetail' style={{ width: '90%', zIndex: '9' }}>
//       <div className='ECourseInfo'>
//         <label>Course Title:</label>
//         <input name="title" value={course.title} onChange={handleInputChange} />

//         <div>
//           <label>Cover image URL:</label>
//           <input name="imgSrc" value={course.imgSrc} onChange={handleInputChange} />
//         </div>

//         <label>Author:</label>
//         <input name="author" value={course.author} onChange={handleInputChange} />

//         <div>
//           <label>Price:</label>
//           <input name="price" value={course.price} onChange={handleInputChange} />
//         </div>

//         <div>
//           <label>Description:</label>
//           <textarea name="description" className='ECourseDescription' value={course.description} onChange={handleInputChange} />
//         </div>

//         <h3 className='ECourseOutlineHeader'>Lessons</h3>
//         <br/>
//         <div className='ECourseOutline' >
//           {course.lessons.map((lesson, index) => (
//             <div className='CourseLesson' key={lesson.id} >
//               <p>Lesson {index+1}</p>
//               <input
//                 name="title"
//                 value={lesson.title}
//                 onChange={(event) => handleLessonChange(index, event)}
//                 placeholder='Lesson title'

//               />
//               {/* <label>Video URL:</label> */}
//               <input
//                 name="videoUrl"
//                 value={lesson.videoUrl}
//                 onChange={(event) => handleLessonChange(index, event)}
//                 placeholder='video Url'
//               />
//             </div>
//           ))}
//         </div>

//         <button className='EAddToCartButton' onClick={() => {setSelectedCourse(null);window.scroll(0,0)}}>Cancel</button>
//         <button className='EAddToCartButton' onClick={handleSaveChanges}>Save Changes</button>
//       </div>
//     </div>
//   );
// };

// export default EditCourse;



import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Context } from './Context';

const EditCourse = () => {
  const { selectedCourse, setSelectedCourse } = useContext(Context);
  const [course, setCourse] = useState({
    title: '',
    author: '',
    price: '',
    imgSrc: '',
    description: '',
    lessons: [],
  });

  // Fetch course data when the selectedCourse changes
  useEffect(() => {
    if (selectedCourse) {
        const loadingAlert = Swal.fire({title:"Fetching Course Details"})
        Swal.showLoading();
      axios.get(`https://elexdondigitalacademy.com/api/get_course.php?id=${selectedCourse}`)
        .then(response => {
          setCourse(response.data.course);
          loadingAlert.close();
        })
        .catch(error => {
          console.error('Error fetching course:', error);
          loadingAlert.close();
        });
    }
  }, [selectedCourse]);

  // Handle input change for course-level fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCourse({
      ...course,
      [name]: value,
    });
  };

  // Handle input change for lesson-level fields
  const handleLessonChange = (index, event) => {
    const { name, value } = event.target;
    const updatedLessons = course.lessons.map((lesson, lessonIndex) =>
      lessonIndex === index ? { ...lesson, [name]: value } : lesson
    );
    setCourse({
      ...course,
      lessons: updatedLessons,
    });
  };

  // Function to add a new lesson
  const handleAddLesson = () => {
    const newLesson = { id: Date.now(), title: '', videoUrl: '' };
    setCourse({
      ...course,
      lessons: [...course.lessons, newLesson],
    });
  };

  // Function to delete a lesson
  const handleDeleteLesson = (index) => {
    const updatedLessons = course.lessons.filter((_, lessonIndex) => lessonIndex !== index);
    setCourse({
      ...course,
      lessons: updatedLessons,
    });
  };

  // Function to handle saving changes
  const handleSaveChanges = () => {
    const loadingAlert = Swal.fire({title:"Updating..."})
    Swal.showLoading();
    axios.post('https://elexdondigitalacademy.com/api/update_course.php', course)
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Course updated successfully!',
          showConfirmButton: false,
          timer: 1500,
        });
        setSelectedCourse(null); // Close the editor after saving
        window.scroll(0,0)
      })
      .catch(error => {
        console.error('Error updating course:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error updating course!',
          text: 'Please try again.',
        });
      });
  };

  return (
    <div className='ECourseDetail' style={{ width: '100%',
     zIndex: '9',
      height:"100%",
      paddingTop:"0px",
      padding:"0px"
      }}>
      <div className='ECourseInfo' style={{width:"100%"}}>
        <label>Course Title:</label>
        <input name="title" value={course.title} onChange={handleInputChange} />

        <div>
          <label>Cover image URL:</label>
          <input name="imgSrc" value={course.imgSrc} onChange={handleInputChange} />
        </div>

        <label>Author:</label>
        <input name="author" value={course.author} onChange={handleInputChange} />

        <div>
          <label>Price:</label>
          <input name="price" value={course.price} onChange={handleInputChange} />
        </div>

        <div>
          <label>Description:</label>
          <textarea name="description" className='ECourseDescription' value={course.description} onChange={handleInputChange} />
        </div>

        <h3 className='ECourseOutlineHeader'>Lessons</h3>
        <br/>
        <div className='ECourseOutline' >
          {course.lessons.map((lesson, index) => (
            <div className='CourseLesson' key={lesson.id} >
              <p>Lesson {index+1}</p>
              <input
                name="title"
                value={lesson.title}
                onChange={(event) => handleLessonChange(index, event)}
                placeholder='Lesson title'
              />
              <input
                name="videoUrl"
                value={lesson.videoUrl}
                onChange={(event) => handleLessonChange(index, event)}
                placeholder='Video URL'
              />
              <button onClick={() => handleDeleteLesson(index)} style={{padding:"5px 10px",backgroundColor:"white",borderRadius:"5px",border:"1px solid rgba(0,0,255,0.5)",color:"rgba(0,0,255,0.5)"}}>Delete</button>
            </div>
          ))}
        </div>

        <button onClick={handleAddLesson} className='EAddToCartButton'>
          Add Lesson
        </button>
        <button className='EAddToCartButton' onClick={() => {setSelectedCourse(null);window.scroll(0,0)}}>Cancel</button>
        <button className='EAddToCartButton' onClick={handleSaveChanges}>Save Changes</button>
      </div>
    </div>
  );
};

export default EditCourse;
