// src/ProfilePage.js
import React from 'react';
import { useSelector } from 'react-redux';
import '../CSS/ProfilePage.css';

const ProfilePage = () => {
  const userInfo = useSelector((state) => state.userInfo);

  return (
    <div className="profile-page">
      <h2>PROFILE</h2>
      <p><strong style={{color:"rgba(0,0,255,0.7)"}}>Name:</strong> {userInfo?.fullName}</p>
      <p><strong style={{color:"rgba(0,0,255,0.7)"}}>Email:</strong> {userInfo?.email}</p>
      <p><strong style={{color:"rgba(0,0,255,0.7)"}}>Phone:</strong> {userInfo?.phoneNumber}</p>
    </div>
  );
};

export default ProfilePage;
