import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useContext } from 'react';
import { Context } from './Context';

const VerifyEmail = () => {
  const location = useLocation();
  const { token } = useParams(); // Token from URL parameters
  const [verificationMessage, setVerificationMessage] = useState('');
  const [verificationMessageError, setVerificationMessageError] = useState('');
  const navigate = useNavigate();
  const { emailVerifyUrl } = useContext(Context);

  useEffect(() => {
    const handleVerify = async () => {
      try {
        // Show a loading message
        Swal.fire({
          title: 'Verifying...',
          text: 'Please wait while we verify your email.',
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          }
        });

        const response = await axios.post(emailVerifyUrl, { token });
        // console.log("Response data:", response.data);

        // Close the loading message
        Swal.close();

        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Verification Successful',
          text: response.data.message,
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          // Navigate to login page after showing the success message
          navigate("/login");
        });

      } catch (error) {
        // Close the loading message
        Swal.close();

        // Show error message
        const errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
        console.error("Verification Error:", error);

        Swal.fire({
          icon: 'error',
          title: 'Verification Failed',
          text: errorMessage,
          showConfirmButton: false,
          timer: 3000
        });
      }
    };

    handleVerify();
  }, [token, emailVerifyUrl, navigate]);

  return (
    <div className='ContactFormWrap' key={location.pathname}>
      <div className="contact-form-container">
        <h2>Email Verification</h2>
        <h2>{verificationMessage}</h2>
        <h2>{verificationMessageError}</h2>
      </div>
    </div>
  );
};

export default VerifyEmail;
