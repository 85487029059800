import React from 'react';
import Youtube from "../Images/youtubeImg.png";
import "../CSS/YoutubeFollow.css";

const YoutubeFollow = () => {
  return (
    <div className="youtube-wrapper">
      <h2>Follow Us on Youtube</h2>
      <img src={Youtube} alt="Youtube Channel" className="youtube-image" />
      <p onClick={()=>window.open('https://www.youtube.com/channel/UCrBjwZIqj6rvlyYyY4oyzdA',"_blank")}>Click to subscribe to our Youtube Channel for more updates and educational materials.</p>
    </div>
  );
}

export default YoutubeFollow;
