import React, { useContext, useEffect, useState } from 'react'
import "../CSS/Hero.css"
import {FaSearch} from "react-icons/fa"
import { Context } from './Context'
import { useNavigate } from 'react-router-dom'

const Hero = () => {
  const navigate = useNavigate()
const {searchInput,setSearchInput,handleSearch,searchResult,navigateState}=useContext(Context)

// console.log(searchInput)
// console.log(searchResult)


useEffect(()=>{
  if(navigateState===true){
    navigate("/searchpage")
  }
},[navigateState===true])


  return (
    <div className='Hero'>
      <p>Unlock a world of tech Knowledge with</p>
      <h1>ELEXDON DIGITAL ACADEMY</h1>
        <div className='SearchWrap'>
          <input 
        value={searchInput}
        onChange={(e)=>setSearchInput(e.target.value)}
        placeholder='Search courses'/>
        
        <button onClick={handleSearch}>
          <p className='SearchText'>Search</p>
          <FaSearch className='SearchIcon'/>
          </button>
          </div>
    </div>
  )
}

export default Hero
