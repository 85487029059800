import React, { useState } from 'react';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [userInfo, setUserInfo] = useState(null);
  console.log(userInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://elexdondigitalacademy.com/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert('Login successful');
          setUserInfo(data.user); // Set user info including the token
        } else {
          alert('Login failed: ' + data.error);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Login failed');
      });
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: "200px 20px" }}>
      <div>
        <label>Username:</label>
        <input type="text" name="username" value={formData.username} onChange={handleChange} required />
      </div>
      <div>
        <label>Password:</label>
        <input type="password" name="password" value={formData.password} onChange={handleChange} required />
      </div>
      <button type="submit">Login</button>
    </form>
  );
};

export default LoginForm;
