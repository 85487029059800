import React from 'react';
import '../CSS/BecomeInstructor.css';
import AffiliateImg from "../Images/affiliateImg1.png"
import { useNavigate } from 'react-router-dom';

const BecomeAffiliate = () => {
    const navigate=useNavigate()
    return (
        <div className="instructor-section instructor-section2">
            <div className="instructor-image">
                <img src={AffiliateImg} alt="Become an Instructor" />
            </div>
            <div className="instructor-content">
                <h2 className="instructor-title">Join Our Affiliate Program</h2>
                <p className="instructor-description">
                Partner with Elexdon Digital Academy and earn by promoting our courses. As an affiliate, you'll have the opportunity to share high-quality educational content with your audience and earn commissions on every sale. Whether you're a blogger, content creator, or have a strong online presence, this is a great way to monetize your influence while helping others learn and grow.
                </p>
                <button className="instructor-button" onClick={() => navigate('/becomeaffiliatepage')}>
                    Learn More
                </button>
                {/* <p>Already an Affliate? <span style={{color:"blue",fontWeight:"bold",textDecoration:"underline",cursor:"pointer",marginTop:"30px"}}>Login as Affiliate</span></p> */}

            </div>
            
        </div>
    );
};

export default BecomeAffiliate;
