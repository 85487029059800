import React, { useContext, useState } from 'react';
import '../CSS/ContactUs.css';
import { FaHome } from 'react-icons/fa';
import bgIm from "../Images/contactusimg2.jpeg";
import { useLocation, useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import Logo from "../Images/ELogo.png";
import axios from 'axios';
import { Context } from './Context';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const location = useLocation();
  const { contactFormUrl } = useContext(Context);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      const response = await axios.post(`${contactFormUrl}`, formData);
      setSubmitting(false);

      if (response.data.status === 'success') {
        setSuccess(true);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your request has been submitted. We will get back to you soon.',
        }).then(() => navigate("/"));
      } else {
        setError(response.data.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message || 'An error occurred while sending your message.',
        });
      }
    } catch (error) {
      setSubmitting(false);
      setError('An error occurred while sending the message.');
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while sending your message.',
      });
    }
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  if (success) {
    return (
      <div className='ContactFormWrap' style={{ position: "relative", color: "blue" }} key={location.pathname} >
        <div className='contact-form-container'>
          <p style={{ position: "relative", textAlign: "center" }}>Your request is submitted and one of our representatives shall get back to you as soon as possible</p>
          <button className='SubmittedButton' style={{ marginTop: "10px" }} onClick={() => navigate("/")}><FaHome /> Home</button>
        </div>
      </div>
    );
  }

  return (
    <div className='ContactFormWrap' style={{ backgroundImage: `url(${bgIm})` }} key={location.pathname}>
      <div className="contact-form-container" style={{ backgroundImage: `url(${bgIm})` }}>
        <h2>Contact Us</h2>
        <img src={Logo} alt="Logo" style={{ position: "relative", width: "70px" }} />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">
            {submitting ? (
              <ClipLoader color={"#ffffff"} loading={submitting} css={override} size={15} />
            ) : (
              "Submit"
            )}
          </button>
        </form>
        <div className="footer-contact" style={{ textAlign: "center", alignItems: "center", color: "blue", position: "relative" }}>
          <br />
          <p>Address: The old Vicarage building <br />
            Trinity Road Old Market Avon<br />
            Bristol– United Kingdom.<br />
          </p>
          <br />
          <p>Email: info@elexdontech.com</p>
          <p>Phone: +44(0)7479305003</p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
