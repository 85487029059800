
import React, { useContext, useState,useRef,useEffect } from 'react'
import { Context } from './Context'
import { FaArrowAltCircleDown, FaCaretRight, FaCartArrowDown, FaChevronDown, FaDashcube, FaUserCircle } from 'react-icons/fa'
import { FaBurger, FaCartShopping, FaElementor, FaMobileScreenButton } from 'react-icons/fa6'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import "../CSS/Header.css"
import "animate.css"
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../Features/Slice'
import Logo from "../Images/ELogo.png"

const Header = () => {
   const userToken = useSelector(state=>state.userToken)
   // console.log(userToken)
   const cart = useSelector(state=>state.cart)
   const dispatch=useDispatch()
    const {loading,setLoading}=useContext(Context)
   //  console.log(loading)
    const navigate = useNavigate()
    const location = useLocation()
    const [menuSwitch,setMenuSwitch]=useState(false)
    const userInfo = useSelector(state=>state.userInfo)

    // handling click away
const menuRef=useRef();
useEffect(()=>{
   const handleClickOutside = (event)=>{
      if(menuRef.current&&!menuRef.current.contains(event.target)){
         setMenuSwitch(false)
      }
   }
   document.addEventListener('mousedown',handleClickOutside)
      return ()=>{
         document.removeEventListener('mousedown',handleClickOutside)
      }
},[])

const handleNavigate = ()=>{
   navigate("/")
   scrollToAbout()
}

const scrollToAbout = ()=>{
   
      const int = setTimeout(()=>{
         const element = document.getElementById('about')
   if(element)
   element.scrollIntoView({behavior:'smooth'})
      },500)

      return ()=>clearInterval(int)
   
}



  return (
    <div className={location.pathname==="/"?'Header':'HeaderB'}>
        <img className='HeaderImg' src={Logo} alt="Logo" onClick={()=>navigate("/")}/>
        
        {/* <h1 style={{color:"white",cursor:"pointer"}} onClick={()=>navigate("/")}><FaElementor/></h1> */}
        <div className='HeaderLeft'>
        <NavLink to={"/"}> HOME</NavLink>
        <p onClick={handleNavigate}>ABOUT</p>
        <NavLink to={"/allcourses"}>COURSES</NavLink>
        <NavLink to={"/userdashboard"} style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}> START LEARNING</NavLink>
        <NavLink to="/contactus">CONTACT US</NavLink>
        </div>

        <div className='HeaderRight'>
        <div className='CartWrap' onClick={()=>navigate("/cartpage")}><FaCartArrowDown/>({cart.length})</div>
        {!userToken&&<button onClick={()=>navigate("/login")}>Login</button>}
        {!userToken&&<button onClick={()=>navigate("/signup")}>Register</button>}
         {userToken&&<div className='CartWrap' onClick={()=>navigate("/userdashboard")}><FaUserCircle /><p> Hi, {userInfo.fullName.slice(0,5)}</p></div>}
        </div>
        <FaBurger style={{color:"white",cursor:"pointer"}} className="Burger" onClick={()=>setMenuSwitch(!menuSwitch)}/>
        {menuSwitch&&<div className='Menu2 animate__animated animate__slideInRight' ref={menuRef}>
        <NavLink to={"/"} onClick={()=>setMenuSwitch(false)}> HOME</NavLink>
        <p onClick={()=>{handleNavigate();setMenuSwitch(false)}}> ABOUT</p>
        <NavLink to={"/allcourses"} onClick={()=>setMenuSwitch(false)}>COURSES</NavLink>
        <NavLink to={"/userdashboard"} style={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={()=>setMenuSwitch(false)}> START LEARNING</NavLink>
        <NavLink to="/contactus" onClick={()=>setMenuSwitch(false)}>CONTACT</NavLink>
        </div>}
    </div>
  )
}

export default Header
