import React from 'react';
import '../CSS/HowItWorks.css';

const HowItWorks = () => {
    return (
        <div className="how-it-works">
            <h2>How It Works</h2>
            <div className="stages-container">
                <div className="stage">
                    <div className="content">
                        <h3>1. Enroll</h3>
                        <p>Enroll in a course that suits your interest and career goals.</p>
                    </div>
                    <div className="arrow">→</div>
                </div>
                <div className="stage">
                    <div className="content">
                        <h3>2. Access Materials</h3>
                        <p>Get access to all course materials and start learning immediately.</p>
                    </div>
                    <div className="arrow">→</div>
                </div>
                <div className="stage">
                    <div className="content">
                        <h3>3. Start Learning</h3>
                        <p>Begin your learning journey at your own pace and convenience.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
