import logo from '../logo.svg';
import '../CSS/LoadingUI.css';

function LoadingUI() {
  return (
    <div className="Loading">
      <header className="Loading-header">
        <img src={logo} className="Loading-logo" alt="logo" />
        <h3>
          ELEXDON ACADEMY
        </h3>
        
      </header>
    </div>
  );
}

export default LoadingUI;
