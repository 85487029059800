// src/EmailVerification.js
import React from 'react';
import '../CSS/ContactUs.css';

const EmailVerification = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email verification submitted');
    // Handle email verification
  };

  return (
    <div className='ContactFormWrap'>
      <div className="contact-form-container">
        <h2>Email Verification</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="verificationCode">Verification Code</label>
            <input
              type="text"
              id="verificationCode"
              name="verificationCode"
              required
            />
          </div>
          <button type="submit">Verify</button>
        </form>
      </div>
    </div>
  );
};

export default EmailVerification;
