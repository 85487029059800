import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../CSS/Testimonials.css";
import StudentPic1 from "../Images/Screenshot 2024-02-02 131255nbsx.png"
import StudentPic2 from "../Images/pic1 1.png"
import StudentPic3 from "../Images/T3.jpeg"


const testimonials = [
  {
    id: 1,
    name: "John",
    feedback: "This online academy has transformed my career. The courses are comprehensive and the instructors are top-notch.",
    imgSrc: StudentPic1
  },
  {
    id: 2,
    name: "Jane",
    feedback: "I love the flexibility of self-paced learning. The content is engaging and easy to follow.",
    imgSrc: StudentPic2
  },
  {
    id: 3,
    name: "Samuel",
    feedback: "The best platform for learning tech skills. The community and support are amazing!",
    imgSrc: StudentPic3
  },
];

const Testimonials = () => {
  return (
    <div className='Testimonials'>
      <h1>What Our Students Say</h1>
      <Carousel 
        showArrows={true} 
        infiniteLoop={true} 
        showThumbs={false} 
        showStatus={false} 
        autoPlay={true} 
        interval={3000}
      >
        {testimonials.map(testimonial => (
          <div key={testimonial.id} className='Testimonial'>
            <img src={testimonial.imgSrc} alt={testimonial.name} className='TestimonialImage' />
            <div className='TestimonialContent'>
              <p>"{testimonial.feedback}"</p>
              <h3>{testimonial.name}</h3>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
