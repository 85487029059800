import React, { useContext } from 'react';
import { FaCartShopping } from 'react-icons/fa6';
import { FaShareAlt } from 'react-icons/fa';
import "../CSS/LatestCourses.css";
import Swal from 'sweetalert2';
import { addToCart } from '../Features/Slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Context } from './Context';

const LatestCourses = () => {
  const { courses } = useContext(Context);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddToCart = (course) => {
    dispatch(addToCart(course));
    Swal.fire({ icon: "success", text: "This course is added to your cart", showConfirmButton: false, timer: 2000 });
  };

  const handleShare = (course) => {
    const courseUrl = `${window.location.origin}/course/${course.id}`;
    const courseDetails = `Check out this course: ${course.title} - ₦${Number(course.price).toLocaleString()}\n${courseUrl}`;
    if (navigator.share) {
      navigator.share({
        title: course.title,
        text: courseDetails,
        url: courseUrl,
      }).catch(error => console.error('Error sharing', error));
    } else {
      navigator.clipboard.writeText(courseDetails);
      alert("Course details copied to clipboard");
    }
  };

  return (
    <div className='LatestCourses'>
      <div className='LatestCoursesHeader'>
        <h1>LATEST COURSES</h1>
        <button onClick={() => navigate("/allcourses")}>View All Courses</button>
      </div>
      <div className='LatestCoursesBody'>
        {courses.slice(0, 8).map(course => (
          <div className='Card' key={course.id}>
            <div className='CardUp'>
              <img src={course.imgSrc} alt={course.title} />
            </div>
            <div className='CardDown'>
              <div className='CardDownAuthor'>
                <span className='CardDownAuthorLeft'>{course.author.slice(0,1)}</span>
                <span className='CardDownAuthorRight'>{course.author}</span>
              </div>
              <h4>{course.title.slice(0,40)}{course.title.length > 40 ? '...' : ""}</h4>
              <span style={{ fontSize: "12px", color: "gray" }}>{course.id}</span>
              <p>₦ {Number(course.price).toLocaleString()}</p>
              <p onClick={() => navigate(`/course/${course.id}`)} style={{ marginBottom: "5px", fontWeight: "400", fontSize: "0.8rem", cursor: "pointer" }}>View More</p>
              <div className='CardButtonAndShareWrap'>
                <button onClick={() => handleAddToCart(course)}><FaCartShopping /> Buy Now</button>
                <FaShareAlt className='CardShare' onClick={() => handleShare(course)} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LatestCourses;
