import React from 'react'
import { FaChevronUp } from 'react-icons/fa'
import "../CSS/ManualScrollToTop.css"

const ManualScrollToTop = () => {
  return (
    <div className='ManualScrollToTop' onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
        <FaChevronUp/>
    </div>
  )
}

export default ManualScrollToTop
