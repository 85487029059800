
import React, { useContext, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../CSS/ContactUs.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from './Context';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import Logo from "../Images/ELogo.png";

const SignUp = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    phoneNumber: '',
    referrerCode:'',
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const { signupUrl } = useContext(Context);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'email' ? value.toLowerCase() : value
    });
    if (name === 'password' || name === 'confirmPassword') {
      if (name === 'confirmPassword') {
        setConfirmPassword(value);
      }
      setPasswordsMatch(formData.password === value || name === 'password' && confirmPassword === value);
    }
  };

  // console.log(formData)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    setIsSubmitting(true); 

    try {
      const response = await axios.post(`${signupUrl}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          text: 'Success. Please check your email inbox or spam folder for verification.',
          confirmButtonText: 'Ok'
        }).then(() => {
          navigate('/login');
        });
      } else {
        // Handle the error response from the backend
        Swal.fire({
          icon: 'error',
          title: 'Sign Up Failed',
          text: response.data.error || 'An error occurred while signing up. Please try again.',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      let errorMessage = 'An error occurred while signing up. Please try again.';

      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          errorMessage = data.error || 'Invalid input. Please check your data and try again.';
        } else if (status === 401) {
          errorMessage = 'Unauthorized. Please check your credentials and try again.';
        } else if (status === 500) {
          errorMessage = 'Server error. Please try again later.';
        }
      }

      Swal.fire({
        icon: 'error',
        title: 'Sign Up Failed',
        text: errorMessage,
        confirmButtonText: 'OK'
      });
    } finally {
      setIsSubmitting(false); 
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className='ContactFormWrap' key={location.pathname}>
      <div className="contact-form-container">
        <h2>Sign Up</h2>
        <img src={Logo} alt="Logo" style={{ position: "relative", width: "70px" }} />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <span
                onClick={toggleShowPassword}
                style={{ position: 'absolute', right: '10px', top: '50%', cursor: 'pointer', transform: 'translateY(-50%)' }}
              >
                {showPassword ? '🙈' : '👁️'}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                required
              />
              <span
                onClick={toggleShowPassword}
                style={{ position: 'absolute', right: '10px', top: '50%', cursor: 'pointer', transform: 'translateY(-50%)' }}
              >
                {showPassword ? '🙈' : '👁️'}
              </span>
            </div>
            {!passwordsMatch && <p style={{ color: 'red', marginTop: '5px' }}>Passwords do not match.</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="referralCode">Referrer Code (Optional)</label>
            <input
              type="text"
              id="referrerCode"
              name="referrerCode"
              value={formData.referrerCode}
              onChange={handleChange}
            />
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <ClipLoader color={"#ffffff"} loading={isSubmitting} css={override} size={15} />
            ) : (
              "Sign Up"
            )}
          </button>
        </form>
        <p style={{ position: "relative", marginTop: "10px" }}>Have an account? <span onClick={() => navigate("/login")} style={{ cursor: "pointer", color: "blue" }}>Login</span></p>
      </div>
    </div>
  );
};

export default SignUp;


