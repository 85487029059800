// import React from 'react'
// import { FaCertificate, FaEnvelope, FaEnvelopeOpenText, FaFile, FaQuestionCircle, FaUserAlt, FaVideo } from 'react-icons/fa'
// import { FaFileCircleQuestion, FaHouseLaptop, FaTextSlash } from 'react-icons/fa6'
// import "../CSS/Features.css"

// const Features = () => {
//   return (
//     <div className='Features'>
      
//         <div className='FeaturesBody'>
//             <div className='Feature'>
//                 <FaVideo className="FeatureIcon" />
//                 <span>Video Courses</span>
//             </div>
//             <div className='Feature'>
//                 <FaFile className="FeatureIcon" />
//                 <span>Text Courses</span>
//             </div>
//             <div className='Feature'>
//                 <FaHouseLaptop className="FeatureIcon" />
//                 <span>Self-Paced Learning</span>
//             </div>
//             <div className='Feature'>
//                 <FaFileCircleQuestion className="FeatureIcon" />
//                 <span>Self-Paced Assessments</span>
//             </div>
//             <div className='Feature'>
//                 <FaCertificate className="FeatureIcon" />
//                 <span>Certification</span>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Features

import React from 'react';
import { FaCertificate, FaFile, FaFileCircleQuestion, FaHouseLaptop, FaVideo } from 'react-icons/fa6';
import "react-multi-carousel/lib/styles.css";
import "../CSS/Features.css";
import Carousel from "react-multi-carousel";

const Features = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const features = [
    { icon: <FaVideo className="FeatureIcon" />, text: "Video Courses" },
    { icon: <FaFile className="FeatureIcon" />, text: "Text Courses" },
    { icon: <FaHouseLaptop className="FeatureIcon" />, text: "Self-Paced Learning" },
    { icon: <FaFileCircleQuestion className="FeatureIcon" />, text: "Self-Paced Assessments" },
    { icon: <FaCertificate className="FeatureIcon" />, text: "Certification" }
  ];

  return (
    <div className='Features'>
      <div className='CarouselWrap'>
      <Carousel 
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        arrows={false}
        showDots={false}
        swipeable={true}
        draggable={true}

    
      >
        {features.map((feature, index) => (
          <div key={index} className='Feature'>
            {feature.icon}
            <span>{feature.text}</span>
          </div>
        ))}
      </Carousel>
      </div>
    </div>
  );
}

export default Features;


