// src/DeliveryDetail.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { setDeliveryDetails } from "../Features/Slice";
import "../CSS/DeliveryDetail.css";
import {useNavigate} from 'react-router-dom'

const DeliveryDetail = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const deliveryDetails = useSelector((state) => state.deliveryDetails);


  const [details, setDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    // Initialize form state with values from Redux store
    setDetails(deliveryDetails);
  }, [deliveryDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({
      ...details,
      [name]: name === 'email' ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setDeliveryDetails(details));
    navigate("/ordersummarypage")
  };

  return (
    <div className="delivery-detail-page">
      <h2>Enter Your Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="delivery-detail-form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={details.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="delivery-detail-form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={details.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="delivery-detail-form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={details.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
};

export default DeliveryDetail;
