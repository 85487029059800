// import React from 'react';
// import { FaShareAlt } from 'react-icons/fa';
// // import { useHistory } from 'react-router-dom';
// import "../CSS/Blogs.css";
// import BlogImg1 from "../Images/course pics.png"
// import BlogImg2 from "../Images/blogImg2.jpeg"
// import BlogImg3 from "../Images/blogimg3.jpeg"
// import { useNavigate } from 'react-router-dom';


// const blogs = [
//   {
//     id: 1,
//     title: "Future of Web Design",
//     excerpt: "Web design is evolving at a rapid pace. In this article, we explore the latest trends and what the future holds for web designers...",
//     content: "Web design is evolving at a rapid pace. In this article, we explore the latest trends and what the future holds for web designers. The future of web design is exciting, with new technologies and design philosophies emerging. One major trend is the increased use of AI in design, which allows for more personalized and efficient user experiences. Additionally, we see a move towards more interactive and immersive designs, utilizing technologies such as VR and AR. It's an exciting time to be a web designer!",
//     imgSrc: BlogImg1
//   },
//   {
//     id: 2,
//     title: "Effective Product Design",
//     excerpt: "Product design is crucial in today's competitive market. This article covers the key principles of effective product design and how to implement them...",
//     content: "Product design is crucial in today's competitive market. This article covers the key principles of effective product design and how to implement them. Effective product design starts with understanding the user. Designers need to conduct thorough research to understand the needs and pain points of their target audience. This information is then used to create user-centric designs that solve real problems. Additionally, designers need to be mindful of usability, aesthetics, and functionality, ensuring that the final product is both beautiful and practical.",
//     imgSrc: BlogImg2
//   },
//   {
//     id: 3,
//     title: "Mastering Front-End Development",
//     excerpt: "Front-end development is essential for creating responsive and interactive websites. Learn how to master front-end development with these tips and resources...",
//     content: "Front-end development is essential for creating responsive and interactive websites. Learn how to master front-end development with these tips and resources. Mastering front-end development requires a strong foundation in HTML, CSS, and JavaScript. Additionally, developers need to be proficient in frameworks like React, Angular, or Vue.js. It's also important to stay up-to-date with the latest trends and best practices, such as responsive design, performance optimization, and accessibility. Continuous learning and practice are key to becoming a proficient front-end developer.",
//     imgSrc: BlogImg3
//   },
// ];

// const Blogs = () => {
// //   const history = useHistory();
//   const navigate =useNavigate()

//   const handleBlogClick = (id) => {
//     // history.push(`/blog/${id}`);
//     navigate(`/blog/${id}`)
//   };

//   return (
//     <div className='Blogs'>
//       <h1>OUR BLOGS</h1>
//       <div className='BlogsBody'>
//         {blogs.map(blog => (
//           <div className='BlogCard' key={blog.id} onClick={() => handleBlogClick(blog.id)}>
//             <img src={blog.imgSrc} alt={blog.title} className='BlogImage' />
//             <div className='BlogContent'>
//               <h2>{blog.title}</h2>
//               <p>{blog.excerpt}</p>
//               <button className='ShareButton'><FaShareAlt /></button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Blogs;



import React from 'react';
import { FaShareAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import "../CSS/Blogs.css";
import BlogImg1 from "../Images/course pics.png";
import BlogImg2 from "../Images/blogImg2.jpeg";
import BlogImg3 from "../Images/blogimg3.jpeg";

const blogs = [
  {
    id: 1,
    title: "Future of Web Design",
    excerpt: "Web design is evolving at a rapid pace. In this article, we explore the latest trends and what the future holds for web designers...",
    content: "Web design is evolving at a rapid pace. In this article, we explore the latest trends and what the future holds for web designers. The future of web design is exciting, with new technologies and design philosophies emerging. One major trend is the increased use of AI in design, which allows for more personalized and efficient user experiences. Additionally, we see a move towards more interactive and immersive designs, utilizing technologies such as VR and AR. It's an exciting time to be a web designer!",
    imgSrc: BlogImg1
  },
  {
    id: 2,
    title: "Effective Product Design",
    excerpt: "Product design is crucial in today's competitive market. This article covers the key principles of effective product design and how to implement them...",
    content: "Product design is crucial in today's competitive market. This article covers the key principles of effective product design and how to implement them. Effective product design starts with understanding the user. Designers need to conduct thorough research to understand the needs and pain points of their target audience. This information is then used to create user-centric designs that solve real problems. Additionally, designers need to be mindful of usability, aesthetics, and functionality, ensuring that the final product is both beautiful and practical.",
    imgSrc: BlogImg2
  },
  {
    id: 3,
    title: "Mastering Front-End Development",
    excerpt: "Front-end development is essential for creating responsive and interactive websites. Learn how to master front-end development with these tips and resources...",
    content: "Front-end development is essential for creating responsive and interactive websites. Learn how to master front-end development with these tips and resources. Mastering front-end development requires a strong foundation in HTML, CSS, and JavaScript. Additionally, developers need to be proficient in frameworks like React, Angular, or Vue.js. It's also important to stay up-to-date with the latest trends and best practices, such as responsive design, performance optimization, and accessibility. Continuous learning and practice are key to becoming a proficient front-end developer.",
    imgSrc: BlogImg3
  },
];

const Blogs = () => {
  const navigate = useNavigate();

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const handleShare = (blog) => {
    if (navigator.share) {
      navigator.share({
        title: blog.title,
        text: blog.excerpt,
        url: window.location.origin + '/blog/' + blog.id,
      }).catch(error => console.error('Error sharing', error));
    } else {
      navigator.clipboard.writeText(window.location.origin + '/blog/' + blog.id);
      alert("URL copied to clipboard");
    }
  };

  return (
    <div className='Blogs'>
      <h1>OUR BLOGS</h1>
      <div className='BlogsBody'>
        {blogs.map(blog => (
          <div className='BlogCard' key={blog.id} onClick={() => handleBlogClick(blog.id)}>
            <img src={blog.imgSrc} alt={blog.title} className='BlogImage' />
            <div className='BlogContent'>
              <h2>{blog.title}</h2>
              <p>{blog.excerpt}</p>
              <button className='ShareButton' onClick={(e) => { e.stopPropagation(); handleShare(blog); }}>
                <FaShareAlt />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;

