import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FaCartShopping } from 'react-icons/fa6';
import { FaShareAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { addToCart } from '../Features/Slice'; // Adjust the path as per your project structure
import { Context } from './Context';
import "../CSS/LatestCourses.css";
import "../CSS/AllCourses.css";
import ManualScrollToTop from './ManualScrollToTop';
import Features from "./Featrures";
import { FaSearch } from 'react-icons/fa';

const AllCourses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses, handleSearch, setSearchInput, searchInput, navigateState } = useContext(Context);

  const handleAddToCart = (course) => {
    dispatch(addToCart(course));
    Swal.fire({ icon: "success", text: "This course is added to your cart", showConfirmButton: false, timer: 2000 });
  };

  const handleShare = (course) => {
    if (navigator.share) {
      navigator.share({
        title: course.title,
        text: course.description,
        url: window.location.origin + '/course/' + course.id,
      }).catch(error => console.error('Error sharing', error));
    } else {
      navigator.clipboard.writeText(window.location.origin + '/course/' + course.id);
      Swal.fire({ icon: "info", text: "URL copied to clipboard", showConfirmButton: false, timer: 2000 });
    }
  };

  useEffect(() => {
    if (navigateState === true) {
      navigate("/searchpage");
    }
  }, [navigateState]);

  return (
    <div className='LatestCourses' style={{ paddingTop: "0px", padding: "0px 0px" }}>
      <div className='AllCoursesHeader'>
        <h1 style={{ color: "rgba(255,255,255,0.9)", textAlign: "center", position: 'relative' }}>ELEXDON DIGITAL ACADEMY COURSES</h1>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignContent: "center", marginTop: "50px", position: "relative" }}>
          <div className='SearchWrap'>
            <input 
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder='Search courses'
            />
            <button onClick={handleSearch}>
              <p className='SearchText'>Search</p>
              <FaSearch className='SearchIcon' />
            </button>
          </div>
        </div>
      </div>
      <Features />
      <div className='LatestCoursesBody' style={{ padding: "100px 0px" }}>
        {courses.map(course => (
          <div className='Card' key={course.id} >
            <div className='CardUp'>
              <img src={course.imgSrc} alt={course.title} />
            </div>
            <div className='CardDown'>
              <div className='CardDownAuthor'>
                <span className='CardDownAuthorLeft'>{course.author.slice(0,1)}</span>
                <span className='CardDownAuthorRight'>{course.author}</span>
              </div>
              <h4>{course.title.slice(0,40)}{course.title.length > 30 ? '...' : ""}</h4>
              <span style={{fontSize:"12px",color:"gray"}}>{course.id}</span>
              <p style={{marginBottom:"5px"}}>₦ {Number(course.price).toLocaleString()}</p>
              <p onClick={() => navigate(`/course/${course.id}`)} style={{marginBottom:"5px",fontWeight:"400",fontSize:"0.8rem",cursor:"pointer"}}>View More</p>
              <div className='CardButtonAndShareWrap'>
                <button onClick={() => handleAddToCart(course)}><FaCartShopping /> Buy Now</button>
                <FaShareAlt className='CardShare' onClick={() => handleShare(course)} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <ManualScrollToTop />
    </div>
  );
}

export default AllCourses;
